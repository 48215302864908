import React from "react";
import Img from "gatsby-image";
import { useAvatar } from "../../utils/custom-hooks/image-hooks";
import SEO from "../../components/SEO/SEO";
import Layout from "../../components/Layout/Layout";
import FlexibleLayout from "../../components/FlexibleLayout";
import SocialShare from "../../components/SocialShare/SocialShare";
import { ReactComponent as TimeSvg } from "../../assets/svgs/time.svg";
import { ReactComponent as CalendarSvg } from "../../assets/svgs/calendar.svg";
import BackButton from "../../components/BackButton/BackButton";

import "./insight.scss";

const insight = ({ data, location, useImage }) => {
  const {
    page: {
      theme,
      title,
      content,
      date,
      uri,
      slug,
      author,
      seo,
      baseFields: {
        previewDescription
      },
      ideaMetaFields,
      flexchildren
    }
  } = data;

  const featuredImage = useImage().featured;
  const avatarImage = useAvatar();

  return (
    <>
      <SEO seoData={seo} uri={uri} useSocialImage={useImage} />
      <Layout bodyClass={slug} theme={theme} location={location}>
        <SocialShare title={title} url={location.href} />
        <section className="IdeaHeader">
          <div className="container">
            <BackButton to="/insights/" variant="mixed">
              Back to insights
            </BackButton>
            <h3 className="Text--light">{title}</h3>
            <div
              className="IdeaShortDescription"
              dangerouslySetInnerHTML={{ __html: previewDescription }}
            />
          </div>
        </section>
        <div className="IdeaContentainer container">
          {featuredImage && (
            <div className="IdeaFeaturedImage">
              <Img fluid={featuredImage.imgData} alt={featuredImage.alt} />
            </div>
          )}
          <div className="IdeaPostMeta">
            <div className="IdeaPostMetaItem">
              <Img fluid={avatarImage.imgData} alt={avatarImage.alt} className="IdeaAvatar" />
              <span className="IdeaAthorName">by {author.node.name}</span>
            </div>
            {ideaMetaFields.minutesToRead && (
              <div className="IdeaPostMetaItem">
                <TimeSvg className="icon" />
                <span>{`${ideaMetaFields.minutesToRead} min read`}</span>
              </div>
            )}
          </div>
          <div className="Ideacontent" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        {flexchildren && flexchildren.map((layout, index) => {
          return <FlexibleLayout key={index} layout={layout}  />;
        })}
      </Layout>
    </>
  );
};

export default insight;
